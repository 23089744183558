import { createStore, persist } from 'easy-peasy'
import { useMemo } from 'react'

import actions from './actions'

const initialState = {
  actividadesPendientesComiteArea: null,
  actividadesPendientesComiteGlobal: null,
  actividadesPendientesSupervisor: null,
  areas: null,
  config: null,
  etapas: null,
  evaluacionAreaPagination: null,
  evaluacionAreaPaginationParams: null,
  loggedIn: false,
  me: null,
  procesosEvaluacion: undefined,
  selectedProcesoEvaluacion: null,
}

// eslint-disable-next-line import/no-mutable-exports
let store

const storeModel = {
  ...initialState,
  ...actions,
}

function initStore(preloadedState = initialState) {
  return createStore(
    persist(
      storeModel,
      {
        storage: 'localStorage',
      },
    ),
    {
      initialState: preloadedState,
    },
  )
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(initState) {
  const memoStore = useMemo(() => initializeStore(initState), [initState])
  return memoStore
}

export default store

import { useStoreActions } from 'easy-peasy'
import { useEffect, useState } from 'react'

import { CenteredLoading } from 'components/Loading'

export default function ConfigProvider({ children }) {
  const updateConfig = useStoreActions((actions) => actions.updateConfig)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    fetch('/config.json')
      .then((res) => res.json())
      .then((data) => {
        updateConfig(data)
        setLoaded(true)
      })
  }, [updateConfig, setLoaded])

  return loaded ? children : <CenteredLoading />
}

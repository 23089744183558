import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import styled from 'styled-components'

import Card from 'components/Card'
import { colors } from 'global-parameters'

const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />

const Loading = (props) => <Spin indicator={antIcon} {...props} />

export const CenteredLoading = (props) => (
  <CenteredLoadingWrapper {...props}>
    <Spin indicator={antIcon} />
  </CenteredLoadingWrapper>
)

export const LoadingCard = () => (
  <Card>
    <CenteredLoading />
  </Card>
)

const CenteredLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background: ${colors.white};
`

export default Loading

import { gql } from '@apollo/client'
import { action, thunk } from 'easy-peasy'

import { getApolloClient } from 'providers/ApiProvider'

const meQuery = gql`
  query IndexMe(
    $start: Int!
    $size: Int!
  ) {
    me {
      id
      username
      firstName
      lastName
      procesosEvaluacion {
        id
        anno
        nombre
        estado
        etapaActual {
          permisosRol {
            tipoDeRol
          }
        }
        evaluados {
          id
          nombreCompleto
        }
        evaluacionesArea(
          pagination: {
            start: $start
            size: $size
          }
        ) {
          stats {
            pendientesCount
          }
        }
        evaluacionesGlobales(
          pagination: {
            start: $start
            size: $size
          }
        ) {
          stats {
            pendientesCount
          }
        }
      }
      rolesUsuario {
        rol {
          nombre
          naturalKey
          tipoDeRol
          permisos {
            etapa {
              nombre
            }
            permiso
          }
        }
        procesoEvaluacion {
          id
          anno
          nombre
          estado
        }
      }
    }
  }
`

const areasQuery = gql`
  query areasQuery {
    areas {
      id
      nombre
      naturalKey
      descripcion
      tipoactividadSet {
        id
        nombre
        schema
        naturalKey
        teacherCanAddActivity
        descripcion
        tabName
      }
    }
  }
`

const etapasQuery = gql`
  query EtapasQuery {
    etapas {
      nombre
      periodo
      descripcion
    }
  }
`

const actividadesPendientesSupervisorQuery = gql`
  query ActividadesSupervisorQuery(
    $procesoDeEvaluacionId: Int!
    $start: Int!
    $size: Int!
  ) {
    me {
      actividadesSupervisor(
        procesoEvaluacionId: $procesoDeEvaluacionId
        pagination: {
          start: $start
          size: $size
        }
      ) {
        stats {
          pendientesCount
        }
      }
    }
  }
`

const logoutMutation = gql`
  mutation Logout {
      logout {
        success
      }
  }
`

const loginMutation = gql`
  mutation Login(
    $sesameToken: String,
    $username: String,
    $password: String
  ) {
    login(
      sesameToken: $sesameToken,
      username: $username,
      password: $password
    ) {
      user {
        username
      }
    }
  }
`

export default {
  cleanAll: thunk(async (actions, _payload, _helpers) => {
    actions.setLoggedIn(false)
    actions.setSelectedProcesoEvaluacion(undefined)
    actions.setActividadesPendientesSupervisor(null)
    actions.setActividadesPendientesComiteArea(null)
    actions.setActividadesPendientesComiteGlobal(null)
    actions.setAreas(undefined)
    actions.setProcesosEvaluacion(undefined)
    actions.setMeEmpty(false)
    actions.setEtapas(null)
    actions.setEvaluacionAreaPagination(null)
    actions.setEvaluacionAreaPaginationParams(null)
  }),
  getActividadesPendientesSupervisor: thunk(async (actions, _payload, helpers) => {
    const { config, selectedProcesoEvaluacion } = helpers.getState()
    const privateClient = getApolloClient(config.apiURL, true, actions.logout)
    try {
      const { data } = await privateClient.query({
        fetchPolicy: 'network-first',
        query: actividadesPendientesSupervisorQuery,
        variables: {
          procesoDeEvaluacionId: selectedProcesoEvaluacion.id,
          size: 10,
          start: 0,
        },
      })
      actions.setActividadesPendientesSupervisor(
        data.me.actividadesSupervisor.stats.pendientesCount,
      )
    } catch (error) {
      return error
    }
    return null
  }),
  getAreas: thunk(async (actions, _payload, helpers) => {
    const { config } = helpers.getState()
    const privateClient = getApolloClient(config.apiURL, true, actions.logout)
    try {
      const { data } = await privateClient.query({ fetchPolicy: 'cache-first', query: areasQuery })
      actions.setAreas(data?.areas)
    } catch (error) {
      actions.setAreas([])
      return error
    }
    return null
  }),
  getEtapas: thunk(async (actions, _payload, helpers) => {
    const { config } = helpers.getState()
    const privateClient = getApolloClient(config.apiURL, true, actions.logout)
    try {
      const { data } = await privateClient.query({ fetchPolicy: 'cache-first', query: etapasQuery })
      actions.setEtapas(data?.etapas)
    } catch (error) {
      actions.setEtapas([])
      return error
    }
    return null
  }),
  login: thunk(async (actions, variables, helpers) => {
    actions.cleanAll()
    const { config } = helpers.getState()
    const publicClient = getApolloClient(config.apiURL, false)
    try {
      await publicClient.mutate({ mutation: loginMutation, variables })
      const updateStoreError = await actions.updateStore()
      if (updateStoreError) {
        throw updateStoreError
      }
    } catch (error) {
      return error
    }
    actions.setLoggedIn(true)
    return null
  }),
  logout: thunk(async (actions, _payload, helpers) => {
    const { config } = helpers.getState()
    const privateClient = getApolloClient(config.apiURL, true)
    try {
      await privateClient.mutate({ mutation: logoutMutation })
    } catch (error) {
      console.error(error)
    }
    actions.cleanAll()
  }),
  reduceActividadesPendientesComiteArea: action((state) => {
    state.actividadesPendientesSupervisor -= 1
  }),
  reduceActividadesPendientesComiteGlobal: action((state) => {
    state.actividadesPendientesComiteGlobal -= 1
  }),
  refreshMe: thunk(async (actions, _payload, helpers) => {
    const { config, selectedProcesoEvaluacion } = helpers.getState()
    const privateClient = getApolloClient(config.apiURL, true, actions.logout)
    try {
      const { data } = await privateClient.query(
        {
          fetchPolicy: 'network-only',
          query: meQuery,
          variables: {
            size: 10,
            start: 0,
          },
        },
      )

      if (data?.me) {
        actions.updateMe(data.me)
        actions.setProcesosEvaluacion(
          data.me.procesosEvaluacion,
        )
        const selectedId = selectedProcesoEvaluacion?.id
        const newProcesoEvaluacion = data.me.procesosEvaluacion
          ? (
            data.me.procesosEvaluacion.find((proceso) => proceso.id === selectedId)
            || data.me.procesosEvaluacion[0]
          )
          : selectedProcesoEvaluacion
        actions.setSelectedProcesoEvaluacion(newProcesoEvaluacion)
        actions.setActividadesPendientesComiteArea(
          newProcesoEvaluacion.evaluacionesArea.stats.pendientesCount,
        )
        actions.setActividadesPendientesComiteGlobal(
          newProcesoEvaluacion.evaluacionesGlobales.stats.pendientesCount,
        )
      }
    } catch (error) {
      return error
    }
    return null
  }),
  setActividadesPendientesComiteArea: action((state, payload) => {
    state.actividadesPendientesComiteArea = payload
  }),
  setActividadesPendientesComiteGlobal: action((state, payload) => {
    state.actividadesPendientesComiteGlobal = payload
  }),
  setActividadesPendientesSupervisor: action((state, payload) => {
    state.actividadesPendientesSupervisor = payload
  }),
  setAreas: action((state, payload) => {
    state.areas = payload
  }),
  setEtapas: action((state, payload) => {
    state.etapas = payload
  }),
  setEvaluacionAreaPagination: action((state, payload) => {
    state.evaluacionAreaPagination = payload
  }),
  setEvaluacionAreaPaginationParams: action((state, payload) => {
    state.evaluacionAreaPaginationParams = payload
  }),
  setLoggedIn: action((state, payload) => {
    state.loggedIn = payload
  }),
  setMeEmpty: action((state) => {
    state.me = null
  }),
  setProcesosEvaluacion: action((state, payload) => {
    state.procesosEvaluacion = payload
  }),
  setSelectedProcesoEvaluacion: action((state, payload) => {
    state.selectedProcesoEvaluacion = payload
  }),
  updateConfig: action((state, payload) => ({
    ...state,
    config: {
      ...state.config,
      ...payload,
      ...state.contactMail,
    },
  })),
  updateMe: action((state, payload) => ({
    ...state,
    me: {
      ...state.me,
      ...payload,
    },
  })),
  updateStore: thunk(async (actions, _payload) => {
    const refreshMeError = actions.refreshMe()
    const areasError = actions.getAreas()
    const etapasError = actions.getEtapas()
    const actividadesPendientesSupervisorError = actions.getActividadesPendientesSupervisor()
    return refreshMeError || areasError || etapasError || actividadesPendientesSupervisorError
  }),
}

import { useRouter } from 'next/router'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect, useState } from 'react'

const publicPaths = ['/login']

export default function RouteGuard({ children }) {
  const loggedIn = useStoreState((state) => state.loggedIn)
  const updateStore = useStoreActions((actions) => actions.updateStore)
  const router = useRouter()
  const [authorized, setAuthorized] = useState(false)

  function authCheck(url) {
    const path = url.split('?')[0]
    if (!loggedIn && !publicPaths.includes(path)) {
      setAuthorized(false)
      router.push({
        pathname: '/login',
      })
    } else {
      setAuthorized(true)
    }
  }

  useEffect(() => {
    authCheck(router.asPath)
    router.events.on('routeChangeComplete', authCheck)
    return () => {
      router.events.off('routeChangeComplete', authCheck)
    }
  }, [router, loggedIn])

  useEffect(() => {
    if (loggedIn && !publicPaths.includes(router.asPath)) {
      updateStore()
    }
  }, [])

  return (authorized && children)
}

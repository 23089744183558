const spaces = {
  l: '36px',
  m: '24px',
  s: '12px',
  xl: '48px',
  xs: '6px',
  xxl: '64px',
  xxs: '3px',
}

export default spaces

import {
  ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from 'apollo-link-context'
import { getCookie } from 'cookies-next'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useMemo } from 'react'

import { CenteredLoading } from 'components/Loading'
import { asyncDefaultFunction } from 'utils'

const customFetch = async (uri, options) => {
  const response = await fetch(uri, options)
  if (response.status >= 400) {
    return Promise.reject(response)
  }
  return response
}

export const getApolloClient = (url, priv = true, onUnauth = asyncDefaultFunction) => {
  const path = priv ? 'graphql' : 'public_graphql'
  const links = [
    onError(({ networkError }) => {
      if (networkError) {
        if ([401, 403].includes(networkError.status)) {
          onUnauth()
        }
        networkError.message = `${networkError}`
      }
    }),
    setContext((_, { headers }) => ({
      headers: {
        ...headers,
        'X-CSRFToken': getCookie('csrftoken'),
        accept: 'application/json',
      },
    })),
    new HttpLink({
      credentials: 'include',
      fetch: customFetch,
      uri: `${url}/${path}`,
    }),
  ]

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from(links),
  })
}

export default function APIProvider(props) {
  const loggedIn = useStoreState((state) => state.loggedIn)
  const config = useStoreState((state) => state.config)
  const logout = useStoreActions((actions) => actions.logout)
  const client = useMemo(
    () => getApolloClient(config.apiURL, loggedIn, logout),
    [config, loggedIn, logout],
  )

  if (!config || !config.apiURL) {
    return <CenteredLoading />
  }

  return <ApolloProvider client={client} {...props} />
}

import { ConfigProvider as AntdConfigProvider } from 'antd'
import esES from 'antd/lib/locale/es_ES'
import { StoreProvider } from 'easy-peasy'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import { colors } from 'global-parameters'
import APIProvider from 'providers/ApiProvider'
import ConfigProvider from 'providers/ConfigProvider'
import { useStore } from 'store/store'
import RouteGuard from 'utils/RouteGuard'

import 'antd/dist/antd.css'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;

    #__next {
      height: 100%;
    }
  }

  * {
    box-sizing: border-box;
  }

  a { 
    text-decoration: none;
    color: ${colors.blue};
  }
`

const theme = {
  colors: {
    primary: colors.primary,
  },
}

export default function App({ Component, pageProps }) {
  const store = useStore(pageProps.ssrStoreState)

  if (typeof window !== 'undefined' && window.Cypress) {
    window.store = store
  }

  return (
    <StoreProvider store={store}>
      <ConfigProvider>
        <APIProvider>
          <RouteGuard>
            <AntdConfigProvider locale={esES}>
              <GlobalStyle />
              <ThemeProvider theme={theme}>
                <Component {...pageProps} />
              </ThemeProvider>
            </AntdConfigProvider>
          </RouteGuard>
        </APIProvider>
      </ConfigProvider>
    </StoreProvider>
  )
}

export const asyncDefaultFunction = async () => ({})
export const defaultFunction = () => ({})

if (typeof (String.prototype.trim) === 'undefined') {
  String.prototype.trim = function () {
    return String(this).replace(/^\s+|\s+$/g, '')
  }
}

export const NOTES_SCALE = ['Malo', 'Insatisfactorio', 'Satisfactorio', 'Bueno', 'Sobresaliente']

export const TIPOS_ACADEMICO = {
  Especial: {
    description: 'Académico especial',
    label: 'E',
  },
  Instructor: {
    description: 'Académico instructor',
    label: 'I',
  },
  Regular: {
    description: 'Académico regular',
    label: 'R',
  },
}

export const getRenderBySchemaType = (type) => {
  if (type === 'int') {
    return undefined
  }
  return undefined
}

export const getSorterBySchemaType = (key, type, nullLast = true) => {
  const multiplier = nullLast ? -1 : 1
  if (type === 'string' || (type.isArray && type.find((element) => element === 'string'))) {
    return (a, b) => {
      if (!a[key]) return -1 * multiplier
      if (!b[key]) return 1 * multiplier
      return a[key].localeCompare(b[key])
    }
  } if (type === 'integer' || (type.isArray && type.find((element) => element === 'integer'))) {
    return (a, b) => {
      if (!a[key]) return -1 * multiplier
      if (!b[key]) return 1 * multiplier
      return a[key] - b[key]
    }
  } if (type === 'number' || (type.isArray && type.find((element) => element === 'number'))) {
    return (a, b) => {
      if (!a[key]) return -1 * multiplier
      if (!b[key]) return 1 * multiplier
      return a[key] - b[key]
    }
  }
  return undefined
}

export const getColumnsFromJsonSchema = (schema) => (schema
  ? Object
    .entries(
      {
        ...schema.properties,
        ...schema.additionalProperties.actividad_persona_schema.properties,
      },
    )
    .map(([key, value]) => ({
      dataIndex: key,
      hide: (value.hide && value.hide.table) || false,
      key,
      order: value.order,
      render: getRenderBySchemaType(value.type),
      sorter: getSorterBySchemaType(key, value.type),
      title: value.label || key,
    })) : [])

export const mergeColumnsByKey = (columns) => columns
  .filter((columnArray) => Array.isArray(columnArray))
  .reduce((acc, columnArray) => {
    const result = [...acc]
    columnArray.forEach((column) => {
      const index = acc.findIndex((c) => c.key === column.key)
      if (index === -1) {
        result.push(column)
      } else {
        result[index] = {
          ...acc[index],
          ...column,
        }
      }
    })
    return result
  }, [])

export const replaceBackendText = (mappingDictionary, text) => Object.entries(mappingDictionary)
  .reduce((previous, [k, v]) => {
    const replace = `{{${k}}}`
    const re = new RegExp(replace, 'g')
    return previous.replace(re, v)
  }, text)

export const hasRequiredPermissions = (
  userRoles, roleTypes, currentEtapaName, requiredPermissions,
) => {
  if (userRoles) {
    const roles = userRoles.filter((rolUsuario) => roleTypes.includes(rolUsuario.rol.tipoDeRol))
    const userPermissions = roles.reduce(
      (permissions, rolUsuario) => (
        permissions.concat(rolUsuario.rol.permisos.reduce(
          (rolPermisos, permisoEtapa) => {
            if (permisoEtapa.etapa.nombre === currentEtapaName) {
              rolPermisos.push(permisoEtapa.permiso)
            }
            return rolPermisos
          },
          [],
        ))
      ),
      [],
    )
    return requiredPermissions.reduce(
      (authorized, permission) => authorized && userPermissions.includes(permission),
      true,
    )
  }
  return requiredPermissions.length === 0
}

export const isRolInProcesoDeEvaluacion = (me, procesoEvaluacion, rol) => Boolean(me.rolesUsuario
  .filter(
    (rolUsuario) => rolUsuario.rol.tipoDeRol === rol
      && rolUsuario.procesoEvaluacion.id === procesoEvaluacion.id,
  ).length)

export const decimalCount = (num) => {
  if (num) {
    const numStr = String(num)
    if (numStr.includes('.')) {
      return numStr.split('.')[1].length
    }
  }
  return 0
}

export const capitalize = (word) => {
  const trimmed = word.trim()
  if (trimmed !== '') {
    const wordArray = word.trim().toLowerCase().split(' ')
    const capitalizedArray = wordArray.map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
    const capitalizedWord = capitalizedArray.join(' ')
    return capitalizedWord
  }
  return word
}

export const isRolInEtapaActual = (etapaActual, rol) => (
  etapaActual.permisosRol.map(
    (permisoRol) => permisoRol.tipoDeRol,
  ).filter(
    (tipoDeRol) => tipoDeRol === rol,
  ).length
)

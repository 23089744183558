const colors = {
  administracion: '#00C7B2',
  backgroundPrimary: '#E5E5E5',
  backgroundSecondary: '#dddddd',
  black: '#000',
  blue: '#2F80ED',
  borderGrey: '#ECF0F2',
  buttonGrey: '#828282',
  docencia: '#6D99F3',
  fontPrimary: '#BDBDBD',
  green: '#2ECC71',
  greyScore: '#E0E0E0',
  highlight: 'rgba(47, 128, 237, 0.06)',
  highlightedTableColumnBackground: '#f4f7fd',
  hoverGreyButton: '#dddddd39',
  indicatorGray: '#9D9D9D',
  investigacion: '#FFA600',
  lightBorder: '#eaeaea',
  lightGrey: '#F2F2F2',
  otras: '#B38DF0',
  paragraphGrey: '#868E96',
  primary: '#0070f3',
  red: '#C41E3A',
  redScore: '#EB5757',
  vinculacion: '#FF71B1',
  white: '#fff',
  whiteButton: '#EAF2FD',
}

export default colors
